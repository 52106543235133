import styles                                                                                     from '../../../styles/components/overlays/bottom_right/CameraMovementsOverlay.module.sass'
import cn                                                                                         from 'classnames'
import {useEffect, useRef, useState}                                                              from 'react'
import {FiMinus, FiPlus, FiRotateCcw, FiRotateCw}                                                 from 'react-icons/fi'
import {gsap}                                                                                     from 'gsap/dist/gsap'
import {FaTimes}                                                                                  from 'react-icons/fa'
import {handleCameraRotateLeft, handleCameraRotateRight, handleCameraZoomIn, handleCameraZoomOut} from '../../../state/actions/general'

const CameraMovementsOverlay = () => {
	// States
	const [isOpened, setIsOpened] = useState(false)

	//region Animation
	const openAnimationTimeline = useRef()
	useEffect(
		() => {
			openAnimationTimeline.current = gsap.timeline({
				                                              defaults: {
					                                              duration: .3,
				                                              },
			                                              })
			                                    .pause()

			openAnimationTimeline.current
			                     .fromTo(
				                     '.camera_movements_overlay .content',
				                     {
					                     overflow: 'hidden',
				                     },
				                     {
					                     overflow: 'visible',
				                     },
			                     )
			                     .fromTo(
				                     '.camera_movements_overlay .opener .open',
				                     {
					                     display: 'flex',
				                     },
				                     {
					                     display:  'none',
					                     duration: .01,
				                     },
				                     '<',
			                     )
			                     .fromTo(
				                     '.camera_movements_overlay .opener .close',
				                     {
					                     display: 'none',
				                     },
				                     {
					                     display:  'flex',
					                     duration: .01,
				                     },
				                     '<',
			                     )
			                     .fromTo(
				                     '.camera_movements_overlay',
				                     {
					                     '--background-color': 'white',
				                     },
				                     {
					                     '--background-color': '#FCF6BF',
				                     },
				                     '<',
			                     )

			                     .fromTo(
				                     '.camera_movements_overlay .content',
				                     {
					                     width:       0,
					                     marginRight: 0,
					                     marginLeft:  0,
				                     },
				                     {
					                     width:       'auto',
					                     marginRight: 16,
					                     marginLeft:  8,
				                     },
				                     '<',
			                     )
			                     .fromTo(
				                     '.camera_movements_overlay',
				                     {overflow: 'hidden'},
				                     {
					                     overflow: 'visible',
				                     },
			                     )
			                     .fromTo(
				                     '.camera_movements_overlay .content .icons',
				                     {
					                     y: 0,
				                     },
				                     {
					                     y: -20,
				                     },
				                     '<',
			                     )
			                     .fromTo(
				                     '.camera_movements_overlay .content .text',
				                     {
					                     opacity: 0,
					                     y:       4,
				                     },
				                     {
					                     opacity: 1,
					                     y:       0,
				                     },
			                     )
		},
		[],
	)

	useEffect(
		() => {
			if (isOpened) {
				openAnimationTimeline.current.play()
			} else {
				openAnimationTimeline.current.reverse()
			}

			return () => {
			}
		},
		[isOpened],
	)
	//endregion

	//region Handlers
	//region Icons
	const isLongPressing    = useRef()
	const handlePointerDown = (callback) => {
		// Handle click
		callback()

		// Start the long press timer and store the timer ID in state
		isLongPressing.current = setInterval(
			() => {
				callback()
			},
			200,
		)
	}

	const handlePointerUp = () => {
		// Clear the long press timer
		clearInterval(isLongPressing.current)
		isLongPressing.current = null
	}
	//endregion
	//endregion

	return (
		<div
			className={cn(
				styles.camera_movements_overlay,
				'camera_movements_overlay',
			)}
		>
			{/*Content*/}
			<div
				className={cn(
					styles.content,
					'content',
				)}
			>
				{/*Icons*/}
				<div
					className={cn(
						styles.icons,
						'icons',
					)}
				>
					{/*Rotate right*/}
					<div
						className={cn(
							styles.icon,
							styles.rotate_right,
						)}

						onPointerDown={() => handlePointerDown(() => handleCameraRotateRight())}
						onPointerUp={() => handlePointerUp()}
					>
						<FiRotateCw/>
					</div>

					{/*Zoom in*/}
					<div
						className={cn(
							styles.icon,
							styles.zoom_in,
						)}
						onPointerDown={() => handlePointerDown(() => handleCameraZoomIn())}
						onPointerUp={() => handlePointerUp()}
					>
						<FiPlus/>
					</div>

					{/*Zoom out*/}
					<div
						className={cn(
							styles.icon,
							styles.zoom_out,
						)}

						onPointerDown={() => handlePointerDown(() => handleCameraZoomOut())}
						onPointerUp={() => handlePointerUp()}
					>
						<FiMinus/>
					</div>

					{/*Rotate left*/}
					<div
						className={cn(
							styles.icon,
							styles.rotate_left,
						)}

						onPointerDown={() => handlePointerDown(() => handleCameraRotateLeft())}
						onPointerUp={() => handlePointerUp()}
					>
						<FiRotateCcw/>
					</div>
				</div>

				{/*Text*/}
				<div
					className={cn(
						styles.text,
						'text',
					)}
				>
					<span>Déplacements</span>
				</div>
			</div>

			{/*Opener*/}
			<div
				className={cn(
					styles.opener,
					'opener',
				)}


				onClick={() => {
					setIsOpened(!isOpened)
				}}
			>
				<FaTimes className={'close'}/>
				<svg
					xmlns='http://www.w3.org/2000/svg'
					viewBox='0 0 24 24'
					width='24'
					height='24'
					className={cn(
						styles.move,
						'open',
					)}
				>
					<path
						fill='none'
						d='M0 0h24v24H0z'
					/>
					<path d='M12 22l-4-4h8l-4 4zm0-20l4 4H8l4-4zm0 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zM2 12l4-4v8l-4-4zm20 0l-4 4V8l4 4z'/>
				</svg>
			</div>
		</div>
	)
}

export default CameraMovementsOverlay
