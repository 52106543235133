import styles                               from '../styles/Header.module.sass'
import NexityLogo                           from '../images/nexity_logo.png'
import {FaBars, FaHeart, FaHistory, FaUser} from 'react-icons/fa'

const Header = () => {
	return <header className={styles.header}>
		{/*Logo*/}
		<div className={styles.logo}>
			<img
				src={NexityLogo}
				alt={'Logo Nexity'}
			/>
		</div>

		{/*Program*/}
		<div className={styles.program}>
			{/*Name*/}
			<span className={styles.name}>Ovation Magellan</span>

			{/*Description*/}
			<span className={styles.description}>Appartement neuf à vendre à Colombes /92</span>
		</div>

		{/*Information and reservation*/}
		<div className={styles.information_and_reservation}>
			{/*Title*/}
			<span className={styles.title}>Information et réservation au :</span>

			{/*Phone number*/}
			<span className={styles.phone_number}>0843 717 700</span>
		</div>

		{/*Links*/}
		<div className={styles.links}>
			{/*History*/}
			<div className={styles.history}>
				<div className={styles.icon}>
					<FaHistory/>
				</div>

				<span className={styles.text}>Historique</span>
			</div>

			{/*Favorites*/}
			<div className={styles.favorites}>
				<div className={styles.icon}>
					<FaHeart/>
				</div>
				<span className={styles.text}>Favoris</span>
			</div>

			{/*My account*/}
			<div className={styles.my_account}>
				<div className={styles.icon}>
					<FaUser/>
				</div>
				<div className={styles.text}>
					<span>Mon compte</span>
				</div>
			</div>

			{/*Menu*/}
			<div className={styles.menu}>
				<div className={styles.icon}>
					<FaBars/>
				</div>

				{/*Text*/}
				<span className={styles.text}>Menu</span>
			</div>

			{/*Spacer*/}
			<div className={styles.spacer}/>
		</div>
	</header>
}

export default Header
