import {useMemo}                  from 'react'
import styles                     from '../../styles/components/overlays/Neighbor.module.sass'
import NeighborhoodEducation      from '../../images/assets/student-hat.svg'
import NeighborhoodCommerce       from '../../images/assets/courses.svg'
import NeighborhoodHealth         from '../../images/assets/sante.svg'
import NeighborhoodSortie         from '../../images/assets/glass-cocktail.svg'
import NeighborhoodTransportBus   from '../../images/assets/directions_bus_black_24dp.svg'
import NeighborhoodTransportTrain from '../../images/assets/directions_railway_filled_black_24dp.svg'
import NeighborhoodTransportTram  from '../../images/assets/tram_black_24dp.svg'
import NeighborhoodTransportMetro from '../../images/assets/train_black_24dp.svg'
import cn                         from 'classnames'
import CheckBoxIcon               from '../../images/assets/check_box light [active].svg'
import {useSnapshot}              from 'valtio'
import {overlayState}             from '../../state/overlay'
import {neighborhoodState}        from '../../state/neighborhood'
import {FaCheck}                  from 'react-icons/fa'


const OverlayNeighborhoodContent = ({}) => {

	// Global state
	const overlayStateSnapshot      = useSnapshot(overlayState)
	const neighborhoodStateSnapshot = useSnapshot(neighborhoodState)

	const filters  = useMemo(() => [
		{
			id:    styles.education,
			title: 'Éducation',
			type:  'education',
			icon:  NeighborhoodEducation,
			color: '#A594FF',
		}, {
			id:    styles.commerce,
			title: 'Commerce',
			type:  'commerce',
			icon:  NeighborhoodCommerce,
			color: '#FFF385',
		}, {
			id:    styles.sante,
			title: 'Santé',
			type:  'health',
			icon:  NeighborhoodHealth,
			color: '#7BFF9F',
		}, {
			id:    styles.sortie,
			title: 'Sortie',
			type:  'outing',
			icon:  NeighborhoodSortie,
			color: '#FFC1F6',
		}, {
			id:    styles.transports,
			title: 'Transports',
			type:  'transport',
			icon:  NeighborhoodTransportBus,
			color: '#69DFFF',
		},
	], [])
	const networks = useMemo(() => [
		{
			title: 'Bus',
			icon:  NeighborhoodTransportBus,
		}, {
			title: 'Train',
			icon:  NeighborhoodTransportTrain,
		}, {
			title: 'Tram',
			icon:  NeighborhoodTransportTram,
		}, {
			title: 'Métro',
			icon:  NeighborhoodTransportMetro,
		},
	], [])

	return (
		<div
			className={cn(styles.neighborhood_content, {[styles.is_shown]: overlayStateSnapshot.filterNeighborhoodShown})}
			style={{
				display: overlayStateSnapshot.currentTab === 'neighborhood'
				         ? 'flex'
				         : 'none',
			}}
		>
			{/*Title*/}
			<span className={styles.title}>Options d'affichage</span>

			{/*Filters*/}
			<div className={styles.filters}>
				{filters.map((item, index) => (
					<div
						key={index}
						className={cn(styles.item, item.id)}

						//region Handlers
						onClick={() => {
							if (neighborhoodState.filters.includes(item.type)) {
								neighborhoodState.filters = neighborhoodState.filters.filter(filter => filter !== item.type)
							} else {
								neighborhoodState.filters = [...neighborhoodState.filters, item.type]
							}
						}}
						//endregion
					>
						{/*Top colored*/}
						<div
							className={styles.top_colored}
							style={{backgroundColor: item.color}}
						>
							{/*Icon*/}
							<img
								src={item.icon}
								className={styles.icon}
								alt={'Icon'}
							/>

							{/*Checkbox*/}
							<img
								src={CheckBoxIcon}
								className={styles.checkbox}
								alt={'Checkbox'}
							/>

							{/*Selected overlay*/}
							{neighborhoodStateSnapshot.filters.includes(item.type) && <div className={styles.selected_overlay}>
								{/*Icon*/}
								<FaCheck/>
							</div>}
						</div>

						{/*Title*/}
						<span className={styles.title}>{item.title}</span>
					</div>
				))}
			</div>


			{/*Networks*/}
			<div className={styles.networks}>
				{/*Title*/}
				<span className={styles.title}>Types de transport</span>

				{/*List*/}
				<div className={styles.list}>
					{networks.map((item, index) => (
						<div
							key={index}
							className={styles.item}
						>
							{/*Icon*/}
							<img
								src={item.icon}
								className={styles.icon}
								alt={'Icon'}
							/>

							{/*Title*/}
							<span className={styles.title}>{item.title}</span>
						</div>
					))}
				</div>
			</div>
		</div>
	)
}

export default OverlayNeighborhoodContent
