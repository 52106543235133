import {proxy} from 'valtio'

const debugState = proxy({
	                         debugMode:         window.location.hash === '#debug',
	                         transformControls: {
		                         objectName:  null,
		                         object:      null,
		                         currentMode: 0,
		                         modes:       ['translate', 'rotate', 'scale'],

		                         set rightClick(target) {
			                         // If target has not changed
			                         if (this.objectName === target) {
				                         this.currentMode =
					                         (
						                         this.currentMode + 1
					                         ) % this.modes.length
			                         }
			                         // Else if target changed
			                         else {
				                         this.objectName  = target
				                         this.currentMode = 0
			                         }
		                         },
		                         showX: true,
		                         showY: true,
		                         showZ: true,
	                         },
	                         gridModeCSS:       false,
                         })

export {debugState}
