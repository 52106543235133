import styles                        from '../styles/Layout.module.sass'
import {useEffect, useRef, useState} from 'react'
import App                           from '../App'
import Overlay                       from './Overlay'
import axios                         from 'axios'
import qs                            from 'qs'
import {useGLTF}                     from '@react-three/drei'
import cn                            from 'classnames'
import Header                        from './Header'
import Footer                        from './Footer'
import {useSnapshot}                 from 'valtio'
import {selectedProgramState}        from '../state/selectedProgram'
import {selectedApartmentState}      from '../state/apartments/selectedApartment'
import {generalState}                from '../state/general'
import BottomRightOverlays           from './overlays/bottom_right/BottomRightOverlays'
//region Images
//region Equipments

//endregion
//region Transports import {Overlay} from './Overlay'
//endregion
//endregion

const getApartmentData = async () => {
	axios.defaults.headers.common['Authorization'] = `Bearer ${process.env.REACT_APP_API_TOKEN}`

	const query = qs.stringify({
		                           populate: {
			                           programme: {
				                           fields:   ['name'],
				                           populate: {
					                           model: {
						                           fields: ['url'],
					                           },

					                           apartments: {
						                           fields: [
							                           'stage', 'rooms',
						                           ],

						                           populate: {
							                           apartment: {
								                           fields:   ['title'],
								                           populate: {
									                           glb_file: {
										                           fields: [
											                           'id', 'url',
										                           ],
									                           },

									                           cameras: {
										                           fields:   [
											                           '*',
										                           ],
										                           populate: {
											                           position:        {
												                           fields: ['x', 'y', 'z'],
											                           },
											                           rotation:        {
												                           fields: ['x', 'y', 'z'],
											                           },
											                           camera_position: {
												                           fields: ['x', 'y', 'z'],
											                           },
										                           },
									                           },
								                           },
							                           },

							                           position: {
								                           fields: ['x', 'y', 'z'],
							                           },
							                           rotation: {
								                           fields: ['x', 'y', 'z'],
							                           },
						                           },
					                           },
				                           },
			                           },
		                           },
	                           }, {encodeValuesOnly: true})

	const program = await axios.get(`${process.env.REACT_APP_STRAPI_API}/api/setting?${query}`)
	                           .then(res => {
		                           const program = res.data.data.attributes.programme.data

		                           // Set glb file
		                           program.attributes.glb_file = `${process.env.REACT_APP_STRAPI_API}${program.attributes.model.data.attributes.url}`

		                           // Preload GLB File
		                           useGLTF.preload(program.attributes.glb_file)

		                           // Preload apartment GLB Files
		                           program.attributes.apartments.forEach(apartmentData => {
			                           apartmentData.apartment.data.attributes.glb_file = `${process.env.REACT_APP_STRAPI_API}${apartmentData.apartment.data.attributes.glb_file.data.attributes.url}`
		                           })

		                           return program
	                           })

	return program
}

const Layout = () => {

	const selectedApartment            = useSnapshot(selectedApartmentState)
	const selectedProgramStateSnapshot = useSnapshot(selectedProgramState)
	const generalStateSnapshot         = useSnapshot(generalState)


	const appRef = useRef()

	//region States
	const [pageToShow, setPageToShow] = useState('building-page')

	// If true, show the iframe, else show an image with some overlay representing selectable apartments
	const [showIframe, setShowIframe] = useState(true)

	// Program data
	const [selectedRoom, setSelectedRoom] = useState(null)
	//endregion

	//region Handlers
	// Component load -> Get all rooms data
	useEffect(() => {
		const loadRooms = async () => {
			selectedProgramState.programData.current = await getApartmentData()
		}

		loadRooms()
			.then()
	}, [])

	// Selected apartment changed
	useEffect(() => {
		if (selectedApartment.apartmentData.current) {
			generalState.currentTab = 'apartments'
		} else {
			generalState.currentTab = 'program'
		}
	}, [selectedApartment.apartmentData.current])
	//endregion

	return (
		<div
			className={cn(styles.page_content, styles.fullscreen, {
				[styles.is_plan_page]: pageToShow === 'plan-page',
			})}
		>
			{/*Header*/}
			<Header/>

			{/*Content with current page component*/}
			<main>
				<Overlay
					pageToShow={pageToShow}
					setPageToShow={setPageToShow}
					showIframe={showIframe}
					setShowIframe={setShowIframe}
					selectedRoom={selectedRoom}
					setSelectedRoom={setSelectedRoom}
				/>
				<App
					selectedRoom={selectedRoom}
					pageToShow={pageToShow}
					ref={appRef}
				/>

				{/*List of apartments*/}
				{/*<ApartmentsList/>*/}

				<BottomRightOverlays/>
			</main>

			<Footer/>
		</div>
	)
}
export default Layout
