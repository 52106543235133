import {Plane, useTexture} from '@react-three/drei'
import * as THREE          from 'three'

const Compass = ({...props}) => {

	const texture = useTexture('/images/Compass.png')

	return (
		<group
			// Rotation to update
			rotation-y={Math.PI + Math.PI / 8}
			{...props}
		>
			<Plane
				rotation-x={-Math.PI / 2}
				args={[2, 2]}
				side={THREE.DoubleSide}
			>
				<meshBasicMaterial
					map={texture}
					transparent={true}
				/>
			</Plane>
		</group>
	)
}

export default Compass
