import {forwardRef, useRef, useState} from 'react'
import {useGesture}                   from '@use-gesture/react'
import {Leva}                         from 'leva'
import WorldCamera                    from './WorldCamera'
import WorldContent                   from './WorldContent'
import {Canvas}                       from '@react-three/fiber'
import {useSnapshot}                  from 'valtio'
import {selectedApartmentState}       from '../../../state/apartments/selectedApartment'
import WorldPostProcessing            from './WorldPostProcessing'

//region Default values
const isDesktop         = window.innerWidth > 1024
const defaultCameraZoom = isDesktop > 1024
                          ? .8
                          : 1.5
const maxCameraZoom     = isDesktop > 1024
                          ? 1.5
                          : 2
const pinchMin          = 1
const pinchMax          = 3
const wheelMin          = -2_000
const wheelMax          = 0

//endregion

const WorldCanvas       = forwardRef(({
	                                      selectedRoom,
	                                      ...props
                                      }, ref) => {

	const selectedApartment = useSnapshot(selectedApartmentState)

	// References
	const canvasRef       = useRef()
	const worldContentRef = useRef()
	const worldCameraRef  = useRef()

	// States
	const [cameraZoom, setCameraZoom] = useState(defaultCameraZoom)

	//region Gestures
	useGesture({
		           onPinch: ({
			                     offset: [s],
		                     }) => {
			           const currentCameraZoom = defaultCameraZoom + (
				           maxCameraZoom * (
					           s - pinchMin
				           ) / (
					           pinchMax - pinchMin
				           )
			           )
			           setCameraZoom(currentCameraZoom)
		           },
		           onWheel: ({
			                     event,
			                     offset: [, oy],
		                     }) => {
			           event.preventDefault()

			           const currentCameraZoom = defaultCameraZoom + (
				           maxCameraZoom * (
					           oy / (
						           wheelMin
					           )
				           )
			           )
			           setCameraZoom(currentCameraZoom)
		           },
	           }, {
		           target: canvasRef,
		           pinch:  {
			           scaleBounds: {
				           min: pinchMin,
				           max: pinchMax,
			           },
			           rubberband:  true,
		           },
		           wheel:  {
			           bounds:       {
				           top:    wheelMin,
				           bottom: wheelMax,
			           },
			           eventOptions: {
				           passive: false,
			           },
			           rubberband:   true,
		           },
	           })
	//endregion

	return <>
		{/*Leva props*/}
		<Leva
			collapsed={true}
			hidden={!window.debug}
		/>

		<Canvas
			ref={canvasRef}
			flat
			shadows
		>
			<fog
				attach={'fog'}
				args={['#EBE7DB', 500, 600]}
			/>

			<group
				name={'main_canvas'}
			>
				<WorldCamera
					cameraZoom={cameraZoom}
					ref={worldCameraRef}
				/>

				<WorldPostProcessing/>

				<WorldContent
					cameraZoom={cameraZoom}
					selectedApartment={selectedApartment}

					ref={worldContentRef}
				/>
			</group>
		</Canvas>
	</>
})
WorldCanvas.displayName = 'WorldCanvas'

export default WorldCanvas
