import {useGLTF}              from '@react-three/drei'
import {selectedProgramState} from '../../../state/selectedProgram'
import {useSnapshot}          from 'valtio'
import {Suspense, useEffect}  from 'react'
import {debugState}           from '../../../state/debug'

const Model = ({
	               atLeastOneApartmentHovered,
	               oneApartmentIsSelected,
	               isCurrentProgram,
	               ...props
               }) => {

	const selectedProgramStateSnapshot = useSnapshot(selectedProgramState)

	//region Model
	const {
		      scene,
		      materials,
		      nodes,
	      } = useGLTF(selectedProgramStateSnapshot.programData.current?.attributes.glb_file)
	//endregion

	//region Handlers
	useEffect(() => {
		// Set node cast and receive shadow
		Object.values(nodes)
		      .forEach(node => {
			      node.castShadow    = true
			      node.receiveShadow = true
		      })
	}, [nodes])

	useEffect(() => {
		// Set node cast shadow
		Object.values(materials)
		      .forEach(mat => {
			      mat.transparent = selectedProgramStateSnapshot.opacity !== 1
			      mat.opacity     = selectedProgramStateSnapshot.opacity
		      })
	}, [materials, selectedProgramStateSnapshot.opacity])
	//endregion

	return (
		<Suspense fallback={null}>
			<group
				name={'program_model'}
				{...props}
				dispose={null}
				position-y={-.4}

				//region Handlers
				onContextMenu={e => {
					debugState.transformControls.rightClick = 'program_model'
				}}
				//endregion
			>
				<primitive
					object={scene}
				/>
			</group>
		</Suspense>
	)
}

export default Model
