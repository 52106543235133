import Model                                      from './Model'
import Apartments                                 from '../Apartment/Apartments'
import {forwardRef, useEffect, useMemo, useState} from 'react'
import {useSnapshot}                              from 'valtio'
import {selectedProgramState}                     from '../../../state/selectedProgram'
import {Plane, useGLTF, useTexture}               from '@react-three/drei'
import * as THREE                                 from 'three'
import ProgramFloor                               from './ProgramFloor'

const Program       = forwardRef(({
	                                  cameraZoom,
                                  }, ref) => {

	const selectedProgram = useSnapshot(selectedProgramState)

	//States
	const [apartmentsHovered, setApartmentsHovered]           = useState([])
	const [oneApartmentIsSelected, setOneApartmentIsSelected] = useState(false)

	const isCurrentProgram = useMemo(() => {
		return true
	}, [])

	useEffect(() => {
		//Preload GLTFs
		selectedProgram.programData.current.attributes.apartments.forEach(apartmentData => {
			useGLTF.preload(apartmentData.apartment.data.attributes.glb_file)
		})
	}, [])

	return (
		<group
			name={'Program'}
			ref={ref}
		>
			{/*Program model*/}
			<Model
				isCurrentProgram={isCurrentProgram}

				atLeastOneApartmentHovered={apartmentsHovered.length > 0}
				oneApartmentIsSelected={oneApartmentIsSelected}
			/>

			{/*Floor*/}
			{/*<ProgramFloor/>*/}

			{isCurrentProgram && <Apartments
				apartments={selectedProgram.programData.current.attributes.apartments}
				cameraZoom={cameraZoom}
				apartmentsHovered={apartmentsHovered}
			/>}
		</group>
	)
})
Program.displayName = 'Program'

export default Program
