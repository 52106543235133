import styles                                   from '../../../styles/components/overlays/bottom_right/ContactOverlay.module.sass'
import {overlayState, setCurrentTabToContactUs} from '../../../state/overlay'
import {useSnapshot}                            from 'valtio'
import cn                                       from 'classnames'

const ContactOverlay = () => {

	const overlayStateSnapshot = useSnapshot(overlayState)

	return (
		<div
			className={cn(
				styles.contact_overlay,
				{[styles.information]: overlayStateSnapshot.currentTab === 'program-information'},
			)}
			onClick={() => {
				setCurrentTabToContactUs()
			}}
		>
			{/*Icon*/}
			<svg
				className={styles.icon}
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 32 30'
			>
				<path
					d='M21.455,13.571a4.175,4.175,0,0,0,4.077-4.286,4.089,4.089,0,1,0-8.168,0A4.186,4.186,0,0,0,21.455,13.571Zm-10.909,0a4.175,4.175,0,0,0,4.077-4.286A4.175,4.175,0,0,0,10.545,5,4.186,4.186,0,0,0,6.455,9.286,4.186,4.186,0,0,0,10.545,13.571Zm0,2.857C7.368,16.429,1,18.1,1,21.429V25H20.091V21.429C20.091,18.1,13.723,16.429,10.545,16.429Zm10.909,0c-.4,0-.845.029-1.323.071a6.092,6.092,0,0,1,2.686,4.929V25H31V21.429C31,18.1,24.632,16.429,21.455,16.429Z'
				/>
			</svg>
		</div>
	)
}

export default ContactOverlay
