import styles         from '../../../styles/components/overlays/bottom_right/FilterApartmentsOverlay.module.sass'
import {overlayState} from '../../../state/overlay'
import {useSnapshot}  from 'valtio'

const FilterApartmentsOverlay = () => {

	const overlayStateSnapshot = useSnapshot(overlayState)

	return (
		<div
			className={styles.filter_overlay}
			onClick={() => {
				overlayState.filtersShown = !overlayStateSnapshot.filtersShown
			}}
		>
			<svg
				className={styles.icon}
				xmlns='http://www.w3.org/2000/svg'
				width='24'
				height='24'
				viewBox='0 0 24 24'
			>
				<path
					d='M3,17v2H9V17ZM3,5V7H13V5ZM13,21V19h8V17H13V15H11v6ZM7,9v2H3v2H7v2H9V9Zm14,4V11H11v2ZM15,9h2V7h4V5H17V3H15Z'
				/>
			</svg>
		</div>
	)
}

export default FilterApartmentsOverlay
