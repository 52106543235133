import {forwardRef}        from 'react'
import {Plane, useTexture} from '@react-three/drei'
import * as THREE          from 'three'

const ProgramFloor       = forwardRef(({
	                                       cameraZoom,
                                       }, ref) => {

	const floorTexture = useTexture('/images/floor.png')

	const floorWidth  = 384
	const floorHeight = 385

	return (
		<group
			name={'ProgramFloor floor'}
			position={[0, 0, -2]}
		>
			<Plane
				args={[floorWidth, floorHeight]}
				rotation-x={-Math.PI / 2}
				receiveShadow
			>
				{/*<meshBasicMaterial*/}
				<meshStandardMaterial
					map={floorTexture}
					// envMapIntensity={.2}
					// metalness={-.5}
				/>
			</Plane>

			<ProgramFloorOutlines
				level={3}
				texture={floorTexture}
				width={floorWidth}
				height={floorHeight}
			/>
		</group>
	)
})
ProgramFloor.displayName = 'Program Floor'

export default ProgramFloor

const ProgramFloorOutlines = ({
	                              level = 1,
	                              texture,
	                              width,
	                              height,
                              }) => {
	return (
		<group
			name={'outlines'}
		>
			{[...Array(level)].map((_, levelIndex) => {
				const index          = levelIndex + 1
				const linesAndColums = index * 2 + 1

				return (
					[...Array(linesAndColums)].map((_, row) => {
						return (
							[...Array(linesAndColums)].map((_, column) => {
								const rowOffset    = row - (
								                     linesAndColums - 1
								) / 2
								const columnOffset = column - (
								                     linesAndColums - 1
								) / 2

								if (!(
									rowOffset === 0 && columnOffset === 0
								)) {
									// Rotation x
									const rotationXValue = (
										(
											Math.abs(rowOffset % 2) - Math.abs(columnOffset % 2)
										) !== 0
										? 1
										: -1
									)
									let rotationX        = rotationXValue * Math.PI / 2

									// Rotation z
									const rotationZValue = -(
										Math.sign(columnOffset) * Math.abs(columnOffset % 2)
									)
									let rotationZ        = rotationZValue * Math.PI

									if (rowOffset === 0 && columnOffset === -2) {
										rotationX = -Math.PI / 2
									}

									return (
										<Plane
											args={[width, height]}
											rotation-x={rotationX}
											rotation-z={rotationZ}
											position={[columnOffset * width, 0, rowOffset * height]}
											key={`${rowOffset}-${columnOffset}`}
										>
											{/*<meshBasicMaterial*/}
											<meshStandardMaterial
												map={texture}
												side={THREE.DoubleSide}
											/>
										</Plane>
									)
								}
							})
						)
					})
				)
			})}
			{false && <group>
				{/*Top left*/}
				<Plane
					args={[width, height]}
					rotation-x={-Math.PI / 2}
					rotation-z={Math.PI}
					position={[-width, 0, -height]}
				>
					{/*<meshBasicMaterial*/}
					<meshStandardMaterial
						map={texture}
					/>
				</Plane>

				{/*Top*/}
				<Plane
					args={[width, height]}
					rotation-x={Math.PI / 2}
					position={[0, 0, -height]}
				>
					{/*<meshBasicMaterial*/}
					<meshStandardMaterial
						map={texture}
						side={THREE.DoubleSide}
					/>
				</Plane>

				{/*Left*/}
				<Plane
					args={[width, height]}
					rotation-x={Math.PI / 2}
					rotation-z={Math.PI}
					position={[-width, 0, 0]}
				>
					{/*<meshBasicMaterial*/}
					<meshStandardMaterial
						map={texture}
						side={THREE.DoubleSide}
					/>
				</Plane>


				{/*REMOVE*/}


				{/*Right*/}
				<Plane
					args={[width, height]}
					rotation-x={Math.PI / 2}
					rotation-z={Math.PI}
					position={[width, 0, 0]}
				>
					{/*<meshBasicMaterial*/}
					<meshStandardMaterial
						map={texture}
						side={THREE.DoubleSide}
					/>
				</Plane>
				{/*Bottom*/}
				<Plane
					args={[width, height]}
					rotation-x={Math.PI / 2}
					position={[0, 0, height]}
				>
					{/*<meshBasicMaterial*/}
					<meshStandardMaterial
						map={texture}
						side={THREE.DoubleSide}
					/>
				</Plane>
				{/*Top right*/}
				<Plane
					args={[width, height]}
					rotation-x={-Math.PI / 2}
					rotation-z={Math.PI}
					position={[width, 0, -height]}
				>
					{/*<meshBasicMaterial*/}
					<meshStandardMaterial
						map={texture}
					/>
				</Plane>
				{/*Bottom right*/}
				<Plane
					args={[width, height]}
					rotation-x={-Math.PI / 2}
					rotation-z={Math.PI}
					position={[width, 0, height]}
				>
					{/*<meshBasicMaterial*/}
					<meshStandardMaterial
						map={texture}
					/>
				</Plane>
				{/*Bottom left*/}
				<Plane
					args={[width, height]}
					rotation-x={-Math.PI / 2}
					rotation-z={Math.PI}
					position={[-width, 0, height]}
				>
					{/*<meshBasicMaterial*/}
					<meshStandardMaterial
						map={texture}
					/>
				</Plane>
			</group>}
		</group>
	)
}
