import {proxy} from 'valtio'

export const overlayState = proxy({
	                                  // Current overlay tab
	                                  currentTab: 'program',

	                                  filtersShown:            false,
	                                  filterNeighborhoodShown: false,
	                                  roomSelectionShown:      false,
	                                  contactFormShown:        false,
                                  })

export const setCurrentTabToContactUs = () => {
	overlayState.currentTab = 'contact-us'
}
