import {useGLTF, useTexture} from '@react-three/drei'
import {useEffect}           from 'react'
//region Assets
//endregion
const BigCity = () => {

	const {
		      scene,
		      materials,
	      } = useGLTF('/ThreeJS_Files/3d_city_sample_terrain.glb')

	useEffect(() => {
		Object.entries(materials)
		      .forEach(([key, material]) => {
			      // Be two times brighter
			      material.metalness             = 0.2
			      material.transmissionIntensity = 3
		      })
		console.log(materials)
	}, [materials])

	return (
		<group
			position={[10, -27, 20]}
			rotation-y={-Math.PI / 2 - Math.PI / 4}
			scale={.9}
		>
			<primitive
				object={scene}
			/>

			<BigCityFloor/>
		</group>
	)
}

export default BigCity


export const BigCityFloor = ({...props}) => {
	const {
		      nodes,
	      } = useGLTF('/ThreeJS_Files/floor.glb')

	const floorTexture = useTexture('/ThreeJS_Files/floor.jpg')

	return (
		<group {...props} dispose={null}>
			<mesh
				geometry={nodes.topo_water.geometry}
				position={[-849.65, 3.63, -810.16]}
				material-map={floorTexture}
			/>
			<mesh
				geometry={nodes.topo_land.geometry}
				position={[-849.65, 0.34, -810.16]}
			>
				<meshStandardMaterial
					map={floorTexture}
					metalness={.5}
				/>
			</mesh>
		</group>
	)
}
