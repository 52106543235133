import {forwardRef, useEffect, useMemo, useRef}              from 'react'
import {button, useControls}                                 from 'leva'
import {OrbitControls, PerspectiveCamera, TransformControls} from '@react-three/drei'
import {useThree}                                            from '@react-three/fiber'
import * as THREE                                            from 'three'
import {useSnapshot}                                         from 'valtio'
import {debugState}                                          from '../../../state/debug'
import {handleCameraZoomBack}                                from '../../../state/actions/general'
import {generalState}                                        from '../../../state/general'
import {Perf}                                                from 'r3f-perf'
import {selectedProgramState}                                from '../../../state/selectedProgram'

const defaultPosition = [0, 30, 150]

const WorldCamera = forwardRef(({
	                                cameraZoom,
                                }, ref) => {
	useSnapshot(generalState)

	const perspectiveCameraRef = useRef()
	const {
		      camera,
	      }                    = useThree()

	useEffect(() => {
		generalState.camera      = camera
		generalState.orbitTarget = orbitControlsRef.current.target
		orbitControlsRef.current.target.set(selectedProgramState.targetPosition[0], selectedProgramState.targetPosition[1], selectedProgramState.targetPosition[2])
	}, [])

	//region Debug
	const debug                  = useSnapshot(debugState)
	const {scene}                = useThree()
	const debugTransformControls = useMemo(() => {
		return {
			object: scene.getObjectByName(debug.transformControls.objectName),
			mode:   debug.transformControls.modes[debug.transformControls.currentMode],
		}
	}, [scene, debug.transformControls])
	//endregion

	// References
	const orbitControlsRef = useRef()

	//region Handlers
	// Set orbit controls globally
	useEffect(() => {
		window.orbitControls = orbitControlsRef.current
	}, [orbitControlsRef.current])
	// Set default camera position
	useEffect(
		() => {
			camera.position.set(defaultPosition[0], defaultPosition[1], defaultPosition[2])

			camera.near = 1
			camera.far  = 600
			camera.updateProjectionMatrix()
		},
		[],
	)
	//endregion

	//region Target
	const targetRef           = useRef()
	const tmpTargetRef        = useRef()
	const [target, setTarget] = useControls(
		'Target',
		() => (
			{
				position:                   {
					x: 0,
					y: 6,
					z: 0,
				},
				setFromOrbitControlsTarget: button(() => {
					setTarget({
						          position: {
							          x: orbitControlsRef.current.target.x,
							          y: orbitControlsRef.current.target.y,
							          z: orbitControlsRef.current.target.z,
						          },
					          })
				}),
				logTarget:                  button(() => {
					const apartment         = tmpTargetRef.current
					const apartmentPosition = new THREE.Vector3(apartment.position[0], apartment.position[1], apartment.position[2])
					const targetPosition    = new THREE.Vector3(targetRef.current.position.x, targetRef.current.position.y, targetRef.current.position.z)

					const roomPosition = targetPosition.sub(apartmentPosition)
					console.log({
						            x: roomPosition.x.toFixed(3),
						            y: roomPosition.y.toFixed(3),
						            z: roomPosition.z.toFixed(3),
					            })
				}),
				logCamera:                  button(() => {
					console.log(targetRef.current.position)
					console.log(perspectiveCameraRef.current.position)
					const cameraPositionFromTarget = perspectiveCameraRef.current.position.clone()
					                                                     .sub(targetRef.current.position)
					console.log(cameraPositionFromTarget.toArray()
					                                    .map(v => v.toFixed(3)))
					/*console.log(perspectiveCameraRef.current.rotation.toArray()
					 .slice(
					 0,
					 3,
					 )
					 .map(v => v.toFixed(3)))*/
				}),
			}
		),
	)
	//endregion

	//region HTML
	return <group
		name={'Camera'}
		ref={ref}
	>
		{debugState.debugMode && <Perf
			position={'top-left'}
		/>}

		<PerspectiveCamera
			zoom={cameraZoom}
			ref={perspectiveCameraRef}
		/>

		{/*Transform controls*/}
		{debugState.debugMode && debugState.transformControls.objectName !== null && <TransformControls
			object={debugTransformControls?.object ?? null}
			mode={debugTransformControls?.mode ?? 'translate'}
			space={'local'}
			showY={debugState.transformControls.showY}
		/>}

		{/*Camera controls*/}
		<OrbitControls
			ref={orbitControlsRef}

			maxPolarAngle={Math.PI / 2}

			// camera={perspectiveCameraRef.current}
			onEnd={e => {
				// Calculate distance from target
				const distance = e.target.object.position.distanceTo(orbitControlsRef.current.target)
				handleCameraZoomBack(distance)
			}}
			makeDefault={true}
		/>

		{/*region Target*/}
		{window.debug && <mesh
			ref={targetRef}
			position={[target.position.x, target.position.y, target.position.z]}
		>
			<sphereBufferGeometry args={[0.01, 32, 32]}/>
			<meshBasicMaterial color={'red'}/>
		</mesh>}
		{/*endregion*/}
	</group>
	//endregion
})

WorldCamera.displayName = 'World camera'

export default WorldCamera
