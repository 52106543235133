import styles from '../styles/Footer.module.sass'

const Footer = () => {
	return (
		<footer className={styles.footer}>
			{/*Links*/}
			<div className={styles.links}>
				<span>Mentions légales</span>
				<span>Conditions générales</span>
				<span>Politique de protection des données</span>
				<span>Centre d’aide</span>
				<span>Service client</span>
				<span>Gestion des cookies</span>
			</div>
		</footer>
	)
}

export default Footer
