import {Bloom, BrightnessContrast, ChromaticAberration, ColorAverage, DepthOfField, DotScreen, EffectComposer, GodRays, Grid, HueSaturation, Noise, Pixelation, Scanline, SMAA, SSAO, ToneMapping, Vignette} from '@react-three/postprocessing'
import {useControls}                                                                                                                                                                                         from 'leva'
import {BlendFunction}                                                                                                                                                                                       from 'postprocessing'
import {useThree}                                                                                                                                                                                            from '@react-three/fiber'
import {forwardRef, useRef}                                                                                                                                                                                  from 'react'
import {Environment}                                                                                                                                                                                         from '@react-three/drei'

const WorldPostProcessing = ({
	                             cameraZoom,
	                             ...props
                             }) => {

	const {scene} = useThree()

	const sunRef = useRef()

	//region Controls
	//region Environment
	const {
		      environmentEnabled,
		      ...environment
	      } = useControls('Environment', {
		environmentEnabled: false,
		background:         false,
		preset:             {
			value:   'sunset',
			options: ['sunset', 'dawn', 'night', 'warehouse', 'forest', 'apartment', 'studio', 'city', 'park', 'lobby'],
		},
	}, {collapsed: true})
	//endregion

	//region Bloom
	const {
		      bloomEnabled,
		      ...bloom
	      } = useControls('Bloom', {
		bloomEnabled:       false,
		intensity:          .16,
		blendFunction:      {
			value:   BlendFunction.SCREEN,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		luminanceThreshold: .10,
		luminanceSmoothing: 0.01,
	}, {collapsed: true})
	//endregion

	//region Chromatic aberration
	const {
		      chromaticAberrationEnabled,
		      ...chromaticAberration
	      } = useControls('Chromatic aberration', {
		chromaticAberrationEnabled: false,
		blendFunction:              {
			value:   BlendFunction.NORMAL,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		offset:                     [.02, .02],
	}, {collapsed: true})
	//endregion

	//region Brightness & Contrast
	const {
		      brightnessContrastEnabled,
		      ...brightnessContrast
	      } = useControls('Brightness & Contrast', {
		brightnessContrastEnabled: true,
		// Brightness
		brightness: {
			value: 0,
			min:   -1,
			max:   1,
			step:  .01,
		},
		// Contrast
		contrast: {
			value: .1,
			min:   -1,
			max:   1,
			step:  .01,
		},
	}, {collapsed: true})
	//endregion

	//region Color Average
	const {
		      colorAverageEnabled,
		      ...colorAverage
	      } = useControls('Color Average', {
		colorAverageEnabled: false, // Color average
		blendFunction:       {
			value:   BlendFunction.NORMAL,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
	}, {collapsed: true})
	//endregion

	//region God rays
	const {
		      godRaysEnabled,
		      sunPosition,
		      sunColor,
		      ...godRays
	      } = useControls('God rays', {
		godRaysEnabled: false, // Color average
		sunPosition:    [0, 50, -15],
		sunColor:       '#fff58d',
		blendFunction:  {
			value:   BlendFunction.SCREEN,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		samples:        30,
		density:        0.97,
		decay:          0.96,
		weight:         0.6,
		exposure:       0.4,
		clampMax:       1,
		blur:           true,
	}, {collapsed: true})
	//endregion

	//region Depth of field
	const {
		      depthOfFieldEnabled,
		      ...depthOfField
	      } = useControls('Depth of Field', {
		depthOfFieldEnabled: false, // Color average
		focusDistance:       0,
		focalLength:         0.02,
		bokehScale:          2,
	}, {collapsed: true})
	//endregion

	//region Dot screen
	const {
		      dotScreenEnabled,
		      ...dotScreen
	      } = useControls('Dot Screen', {
		dotScreenEnabled: false,
		blendFunction:    {
			value:   BlendFunction.NORMAL,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		angle:            {
			value: Math.PI * 0.5,
			min:   0,
			max:   Math.PI * 2,
			step:  Math.PI / 16,
		},
		scale:            1.0,
	}, {collapsed: true})
	//endregion

	//region Grid
	const {
		      gridEnabled,
		      ...grid
	      } = useControls('Grid', {
		gridEnabled:   false,
		blendFunction: {
			value:   BlendFunction.NORMAL,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		scale:         1.0,
		lineWidth:     0.0,
	}, {collapsed: true})
	//endregion

	//region Hue Saturation
	const {
		      hueSaturationEnabled,
		      ...hueSaturation
	      } = useControls('Hue Saturation', {
		hueSaturationEnabled: false,
		blendFunction:        {
			value:   BlendFunction.NORMAL,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		hue:                  0.0,
		saturation:           0.0,
	}, {collapsed: true})
	//endregion

	//region Noise
	const {
		      noiseEnabled,
		      ...noise
	      } = useControls('Noise', {
		noiseEnabled:  false,
		blendFunction: {
			value:   BlendFunction.NORMAL,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		premultiply:   false,
	}, {collapsed: true})
	//endregion

	//region Pixelation
	const {
		      pixelationEnabled,
		      ...pixelation
	      } = useControls('Pixelation', {
		pixelationEnabled: false,
		granularity:       30,
	}, {collapsed: true})
	//endregion

	//region Scan line
	const {
		      scanLineEnabled,
		      ...scanLine
	      } = useControls('Scan Line', {
		scanLineEnabled: false,
		blendFunction:   {
			value:   BlendFunction.NORMAL,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		density:         1.25,
	}, {collapsed: true})
	//endregion

	//region SMAA
	const {
		      smaaEnabled,
		      ...smaa
	      } = useControls('SMAA', {
		smaaEnabled: true,
	}, {collapsed: true})
	//endregion

	//region SSAO
	const {
		      ssaoEnabled,
		      ...ssao
	      } = useControls('SSAO', {
		ssaoEnabled:          false,
		blendFunction:        {
			value:   BlendFunction.NORMAL,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		distanceScaling:      true,
		depthAwareUpsampling: true,
		samples:              30,
		rings:                4,
		distanceThreshold:    1.0,
		distanceFalloff:      0.0,
		rangeThreshold:       0.5,
		rangeFalloff:         0.1,
		minRadiusScale:       .33,
		luminanceInfluence:   0.9,
		radius:               20,
		intensity:            90.0,
		scale:                .5,
		bias:                 0.5,
		fade:                 .01,
	}, {collapsed: true})
	//endregion

	//region Tone mapping
	const {
		      toneMappingEnabled,
		      ...toneMapping
	      } = useControls('Tone Mapping', {
		toneMappingEnabled: true,
		blendFunction:      {
			value:   BlendFunction.SCREEN,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		adaptive:           true, // Resolution : 256, 512, 1024, 2048
		resolution:         {
			value:   256,
			options: [
				2, 4, 8, 16, 32, 64, 128, 256, 512, 1024, 2048,
			],
		},
		middleGrey:         0.6,
		maxLuminance:       16.0,
		averageLuminance:   1.0,
		adaptationRate:     1.0,
	}, {collapsed: true})
	//endregion

	//region Vignette
	const {
		      vignetteEnabled,
		      ...vignette
	      } = useControls('Vignette', {
		vignetteEnabled: false,
		blendFunction:   {
			value:   BlendFunction.SCREEN,
			options: {
				normal:   BlendFunction.NORMAL,
				darken:   BlendFunction.DARKEN,
				multiply: BlendFunction.MULTIPLY,
				average:  BlendFunction.AVERAGE,
				add:      BlendFunction.ADD,
				lighten:  BlendFunction.LIGHTEN,
				screen:   BlendFunction.SCREEN,
				overlay:  BlendFunction.OVERLAY,
			},
		},
		offset:          1.0,
		darkness:        1.0,
		eskil:           false,
	}, {collapsed: true})
	//endregion
	//endregion

	return (
		<group name={'postprocessing'}>
			{/*Environment*/}
			{environmentEnabled && <Environment
				{...environment}
			/>}

			<EffectComposer>
				{/*Bloom*/}
				{bloomEnabled && <Bloom
					{...bloom}
				/>}

				{/*Chromatic aberration*/}
				{chromaticAberrationEnabled && <ChromaticAberration
					{...chromaticAberration}
				/>}

				{/*God rays*/}
				{godRaysEnabled && <>
					<Sun
						ref={sunRef}
						sunPosition={sunPosition}
						sunColor={sunColor}
					/>
					{sunRef.current && <GodRays
						{...godRays}
						sun={sunRef.current}
					/>}
				</>}

				{/*Brightness contrast*/}
				{brightnessContrastEnabled && <BrightnessContrast
					{...brightnessContrast}
				/>}

				{/*Color average*/}
				{colorAverageEnabled && <ColorAverage
					{...colorAverage}
				/>}

				{/*Depth of field*/}
				{depthOfFieldEnabled && <DepthOfField
					{...depthOfField}
				/>}

				{/*Dot screen*/}
				{dotScreenEnabled && <DotScreen
					{...dotScreen}
				/>}

				{/*Grid*/}
				{gridEnabled && <Grid
					{...grid}
				/>}

				{/*Hue saturation*/}
				{hueSaturationEnabled && <HueSaturation
					{...hueSaturation}
				/>}

				{/*Noise*/}
				{noiseEnabled && <Noise
					{...noise}
				/>}

				{/*Pixelation*/}
				{pixelationEnabled && <Pixelation
					{...pixelation}
				/>}

				{/*Scan line*/}
				{scanLineEnabled && <Scanline
					{...scanLine}
				/>}


				{/*SMAA*/}
				{smaaEnabled && <SMAA
					{...smaa}
				/>}

				{/*SSAO*/}
				{ssaoEnabled && <SSAO
					{...ssao}
				/>}


				{/*Tone mapping*/}
				{toneMappingEnabled && <ToneMapping
					{...toneMapping}
				/>}

				{/*Vignette*/}
				{vignetteEnabled && <Vignette
					{...vignette}
				/>}
			</EffectComposer>
		</group>
	)
}

export default WorldPostProcessing


const Sun = forwardRef(function Sun({
	                                    sunPosition,
	                                    sunColor,
                                    }, ref) {
	return (
		<mesh
			ref={ref}
			position={sunPosition}
		>
			<sphereGeometry args={[1, 36, 36]}/>
			<meshBasicMaterial color={sunColor}/>
		</mesh>
	)
})
