import styles         from '../../styles/components/overlays/PriceAndPlan.module.sass'
import {FaTimes}      from 'react-icons/fa'
import {useSnapshot}  from 'valtio'
import {generalState} from '../../state/general'
import {overlayState} from '../../state/overlay'


const OverlayPriceAndPlan = ({
	                             goBackToPreviousTab,
                             }) => {

	// Global state
	const overlayStateSnapshot = useSnapshot(overlayState)

	return (
		<div
			className={styles.price_and_plan}
			style={{
				display: overlayStateSnapshot.currentTab === 'price-and-plan'
				         ? 'flex'
				         : 'none',
			}}
		>
			{/*Close button*/}
			<div
				className={styles.close_button}
				onClick={goBackToPreviousTab}
			><FaTimes className={styles.icon}/></div>

			{/*Phone section*/}
			<div className={styles.phone_section}>
				{/*Title*/}
				<span className={styles.title}>Discuter avec nos conseillers</span>

				{/*Phone number*/}
				<span className={styles.phone_number}>0 800 97 50 50</span>

				{/*Description*/}
				<span className={styles.description}>(Service et appel gratuits)<br/>Nos conseillers vous répondent du lundi au vendredi de 9h à 21h, le samedi de 9h à 20h, le dimanche et jours fériés de 10h à 18h</span>
			</div>

			{/*Demande d'information*/}
			<div className={styles.demande_information}>
				{/*Title*/}
				<span className={styles.title}>Demande d'information</span>

				{/*Form*/}
				<div className={styles.form}>
					{/*Last name*/}
					<label className={styles.last_name}>
						<span className={styles.text}>Nom*</span>
						{/*Input*/}
						<input
							type={'text'}
							className={styles.input}
						/>
					</label>

					{/*First name*/}
					<label className={styles.first_name}>
						<span className={styles.text}>Prénom*</span>
						{/*Input*/}
						<input
							type={'text'}
							className={styles.input}
						/>
					</label>

					{/*Email address*/}
					<label className={styles.email_address}>
						<span className={styles.text}>Adresse mail*</span>
						{/*Input*/}
						<input
							type={'text'}
							className={styles.input}
						/>
					</label>

					{/*Phone number*/}
					<label className={styles.phone_number}>
						<span className={styles.text}>Téléphone*</span>
						{/*Input*/}
						<input
							type={'text'}
							className={styles.input}
						/>
					</label>

					{/*Message (optional)*/}
					<label className={styles.message}>
						<span className={styles.text}>Message facultatif</span>
						{/*Input*/}
						<textarea rows={3}></textarea>
					</label>

					{/*Checkbox accept data*/}
					<label className={styles.checkbox_accept_data}>
						{/*Checkbox*/}
						<input type='checkbox'/>
						{/*Text*/}
						<span className={styles.text}>J'accepte que mes données soient traitées par Nexity, et/ou ses partenaires dans le cadre de ma demande et de la relation commerciale qui pourrait en découler</span>
					</label>

					{/*Choice - Receive personalised offers from Nexity group*/}
					<div className={styles.choice_receive_personalised_offers}>
						{/*Text*/}
						<span className={styles.text}>Recevoir les offres personnalisées du groupe Nexity</span>

						{/*Choices*/}
						<div className={styles.choices}>
							{/*Choice - Yes*/}
							<label className={styles.choice}>
								{/*Radio*/}
								<input
									type='radio'
									name='receive_personalised_offers'
								/>
								{/*Text*/}
								<span className={styles.text}>Oui</span>
							</label>

							{/*Choice - No*/}
							<label className={styles.choice}>
								{/*Radio*/}
								<input
									type='radio'
									name='receive_personalised_offers'
								/>
								{/*Text*/}
								<span className={styles.text}>Non</span>
							</label>
						</div>
					</div>

					{/*Choice - Receive personalised offers from Nexity partners*/}
					<div className={styles.choice_receive_personalised_partners_offers}>
						{/*Text*/}
						<span className={styles.text}>Recevoir les offres personnalisées des partenaires du groupe Nexity</span>

						{/*Choices*/}
						<div className={styles.choices}>
							{/*Choice - Yes*/}
							<label className={styles.choice}>
								{/*Radio*/}
								<input
									type='radio'
									name='receive_personalised_offers'
								/>
								{/*Text*/}
								<span className={styles.text}>Oui</span>
							</label>

							{/*Choice - No*/}
							<label className={styles.choice}>
								{/*Radio*/}
								<input
									type='radio'
									name='receive_personalised_offers'
								/>
								{/*Text*/}
								<span className={styles.text}>Non</span>
							</label>
						</div>
					</div>

					{/*Send button*/}
					<button className={styles.send_button}>Envoyer ma demande</button>

					{/*Legal notices*/}
					<div className={styles.legal_notices}>
						{/*Title*/}
						<span className={styles.title}>Mentions légales :</span>
						{/*Content*/}
						<span className={styles.content}>Nexity, en sa qualité de responsable de traitement, réalise des traitements de données à caractère personnel. Ces traitements sont rendus nécessaires pour répondre à votre demande de contact et sont fondés sur votre consentement préalable. Les données collectées sont destinées aux services concernés du groupe Nexity, et le cas échéant à ses sous-traitants et prestataires. Les sous-traitants et prestataires en question sont soumis à une obligation de confidentialité et ne peuvent utiliser vos données qu'en conformité avec nos dispositions contractuelles et la législation applicable. Elles sont conservées pendant une durée ne pouvant excéder 3 ans à compter du dernier contact de votre part. Vous avez la possibilité d'exercer vos droits pour accéder, rectifier, effacer vos données, limiter leurs traitements, vous y opposer et demander la portabilité de celles-ci. Vous pouvez également définir des directives relatives à la conservation, à l'effacement et à la communication de vos données à caractère personnel après votre décès. Ces droits s'exercent via le lien suivant : https://app.witik.io/fr/form/nexity/exercice-des-droits-rgpd ou par courrier postal à l'attention du Délégué à la Protection des Données (DPO) - Nexity SA - 19 Rue de Vienne - TSA 50029 - 75801 PARIS Cedex 08, accompagné d’une copie d’un titre d’identité. Vous pouvez également adresser une réclamation auprès de la Commission Nationale de l'Informatique et des Libertés - 3 place de Fontenoy - TSA 80715 - 75334 PARIS Cedex 07. Enfin, nous vous informons de l'existence d'une liste d'opposition au démarchage téléphonique prévue par le Code de la Consommation à l'article L.223-1.</span>
					</div>
				</div>
			</div>
		</div>
	)
}

export default OverlayPriceAndPlan
