import {selectedApartmentState} from '../apartments/selectedApartment'
import {selectedProgramState}   from '../selectedProgram'
import * as THREE               from 'three'
import {gsap}                   from 'gsap/dist/gsap'
import {generalState}           from '../general'

//region Camera
//region Camera zoom
const handleCameraZoom = (distance) => {
	// Get camera position
	const cameraPosition = generalState.camera.position
	const orbitTarget    = generalState.orbitTarget

	// Calculate direction vector from camera to orbit target
	const direction = new THREE.Vector3().subVectors(orbitTarget, cameraPosition)
	                                     .normalize()

	// Calculate new camera position
	const newCameraPosition = cameraPosition.clone()
	                                        .sub(direction.multiplyScalar(distance))

	// Update camera position
	gsap.to(generalState.camera.position, {
		x: newCameraPosition.x,
		y: newCameraPosition.y,
		z: newCameraPosition.z,
	})
}

export const handleCameraZoomOut = (distance = 10) => {
	handleCameraZoom(distance)
}

export const handleCameraZoomIn = (distance = 10) => {
	handleCameraZoom(-distance)
}
//endregion
//region Camera rotate
const handleCameraRotate = (rotation) => {
	// Get camera position
	const cameraPosition = generalState.camera.position
	// Get target position
	const orbitTarget    = generalState.orbitTarget

	// Calculate the direction vector from camera to orbit target
	const direction = new THREE.Vector3().subVectors(cameraPosition, orbitTarget)

	// Rotate the direction vector around the up axis
	direction.applyAxisAngle(new THREE.Vector3(0, 1, 0), rotation)

	// Calculate the new camera position by adding the rotated direction vector to the orbit target
	const newCameraPosition = new THREE.Vector3().addVectors(orbitTarget, direction)

	// Update camera position
	gsap.to(generalState.camera.position, {
		x: newCameraPosition.x,
		y: newCameraPosition.y,
		z: newCameraPosition.z,
	})
}

export const handleCameraRotateLeft = (rotation = Math.PI / 32) => {
	handleCameraRotate(rotation)
}

export const handleCameraRotateRight = (rotation = Math.PI / 32) => {
	handleCameraRotate(-rotation)
}
//endregion
//endregion

export const handleCameraZoomBack = (distance) => {
	const maxDistance = 50
	if (distance > maxDistance) {
		if (selectedProgramState.programData.current !== null) {
			// selectedProgramState.programData.current     = null
			selectedApartmentState.apartmentData.current = null
			selectedApartmentState.apartmentGroupName    = null
		}
	} else if (selectedApartmentState.apartmentData.current) {
		const opacity = Math.max(0,
		                         (
			                         distance / maxDistance
		                         ) - .75,
		) * 4

		const showProgram          = opacity > 0 || distance === maxDistance
		selectedProgramState.shown = showProgram

		if (opacity > 0) {
			// Update program opacity
			selectedProgramState.opacity = opacity
		}
	}
}
