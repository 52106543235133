import {useControls}                          from 'leva'
import {useEffect, useRef}                    from 'react'
import {useHelper}                            from '@react-three/drei'
import {CameraHelper, DirectionalLightHelper} from 'three'
import {selectedApartmentState}               from '../../../state/apartments/selectedApartment'
import {useSnapshot}                          from 'valtio'
import {useThree}                             from '@react-three/fiber'

const Lights = () => {

	const selectedApartmentStateSnapshot = useSnapshot(selectedApartmentState)

	const {scene} = useThree()

	//region Leva
	//region Ambient light
	const {
		      ambientLightEnabled,
		      ...ambientLightProps
	      } = useControls('Ambient light', {
		ambientLightEnabled: true,
		// color:               '#efeeeb',
		color: '#ffe3d1',
	})
	//endregion

	//region Directional light
	const directionalLightRef = useRef()
	const {
		      showDirectionalLightHelper,
		      directionalLightEnabled,
		      ...directionalLightProps
	      }                   = useControls('Directional light', {
		showDirectionalLightHelper: false,
		directionalLightEnabled:    true, // position:                   [10, 25, 20],
		position:                   [7, 90, -13], // position:  [0, 14, 0],
		intensity:                  .4,

		color:      '#ffe3d1',
		castShadow: true,
	})

	useHelper(directionalLightEnabled && showDirectionalLightHelper && directionalLightRef, DirectionalLightHelper, 5, 'red')

	const shadowCameraRef = useRef()
	useHelper(directionalLightEnabled && showDirectionalLightHelper && shadowCameraRef, CameraHelper)
	//endregion
	//endregion

	useEffect(() => {
		if (directionalLightRef.current) {
			const target = scene.getObjectByName(selectedApartmentState.apartmentGroupName)
			if (target) {
				directionalLightRef.current.target = target
				shadowCameraRef.current.fov        = 10
				shadowCameraRef.current.far        = 100
				shadowCameraRef.current.updateProjectionMatrix()
			} else {
				directionalLightRef.current.target.position.set(
					0,
					0,
					0,
				)
				directionalLightRef.current.target.updateMatrixWorld()

				shadowCameraRef.current.fov = 100
				shadowCameraRef.current.far = 120
				// Update projection matrix
				shadowCameraRef.current.updateProjectionMatrix()
			}
		}
	}, [selectedApartmentState.apartmentGroupName])

	return (
		<group name={'Lights'}>
			{/*Ambient light*/}
			{ambientLightEnabled && <ambientLight {...ambientLightProps} intensity={selectedApartmentStateSnapshot.apartmentData.current
			                                                                        ? .6
			                                                                        : .55}
			/>}

			{/*Directional light*/}
			{directionalLightEnabled && selectedApartmentStateSnapshot.apartmentData?.current === null && <directionalLight
				ref={directionalLightRef}
				{...directionalLightProps}
			>
				<perspectiveCamera
					ref={shadowCameraRef}
					attach='shadow-camera'
				/>
			</directionalLight>}
		</group>
	)
}

Lights.displayName = 'Lights'

export default Lights
