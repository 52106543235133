import {proxy}                  from 'valtio'
import {gsap}                   from 'gsap/dist/gsap'
import {selectedApartmentState} from './apartments/selectedApartment'

export const selectedProgramState = proxy({
	                                          groupRef:       null,
	                                          programData:    {
		                                          current: null,
	                                          },
	                                          opacity:        1,
	                                          shown:          true,
	                                          targetPosition: [0, 7, 0],
                                          })

export const updateProgramOpacity = (timeline = gsap.timeline()) => {
	const isApartmentSelected = selectedApartmentState.apartmentData.current !== null
	timeline.to(selectedProgramState, {
		opacity: isApartmentSelected
		         ? 0
		         : 1,
	}, '<')

	if (!isApartmentSelected) {
		selectedProgramState.shown = true
	} else {
		timeline.call(() => {
			selectedProgramState.shown = false
		}, null, '>')
	}
}
