import styles            from '../../../styles/components/overlays/bottom_right/SelectRoomOverlay.module.sass'
import {AiOutlineZoomIn} from 'react-icons/ai'
import {overlayState}    from '../../../state/overlay'
import {useSnapshot}     from 'valtio'

const SelectRoomOverlay = () => {

	const overlayStateSnapshot = useSnapshot(overlayState)

	return (
		<div
			className={styles.select_room_overlay}
			onClick={() => {
				overlayState.roomSelectionShown = !overlayStateSnapshot.roomSelectionShown
			}}
		>
			{/*Icon*/}
			<AiOutlineZoomIn className={styles.icon}/>
		</div>
	)
}

export default SelectRoomOverlay
