import './styles/globals.sass'
import Plan_Page                                          from './components/Plan_Page'
import WorldCanvas                                        from './components/3D/World/WorldCanvas'
import {forwardRef, useEffect, useMemo, useRef, useState} from 'react'
import Actions                                            from './state/actions'
import {generalState}                                     from './state/general'
import {useSnapshot}                                      from 'valtio'
import cn                                                 from 'classnames'
import {debugState}                                       from './state/debug'

const debug  = window.location.hash === '#debug'
window.debug = debug

const App       = forwardRef(({
	                              selectedRoom,
	                              pageToShow,
                              }, ref) => {

	const generalStateSnapshot = useSnapshot(generalState)
	const debugStateSnapshot   = useSnapshot(debugState)

	//region Define actions
	useEffect(() => {
		Actions()
	}, [])
	//endregion

	const worldCanvasRef = useRef()

	//region Window width
	const [windowWidth, setWindowWidth] = useState(0)
	const isDesktop                     = useMemo(() => windowWidth > 1024, [windowWidth])

	useEffect(() => {
		setWindowWidth(window.innerWidth)
		window.addEventListener('resize', () => {
			setWindowWidth(window.innerWidth)
		})
	}, [])

	useEffect(() => {
		generalState.isDesktop = isDesktop
	}, [isDesktop])
	//endregion

	return (
		<>
			<div className={cn('fullscreen_section', {['grid_css']: debugStateSnapshot.gridModeCSS})}>
				{pageToShow === 'building-page' && <WorldCanvas
					selectedRoom={selectedRoom}
					ref={worldCanvasRef}
				/>}

				{pageToShow === 'plan-page' && generalStateSnapshot.isDesktop && <Plan_Page/>}
			</div>
		</>
	)
})
App.displayName = 'App'

export default App
