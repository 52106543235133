import {forwardRef, Suspense, useEffect} from 'react'
import {useGLTF}                         from '@react-three/drei'

const Model = forwardRef(({
	                          modelUrl,
	                          envMapIntensity = 1,
	                          hovered,
	                          ...props
                          }, ref) => {
	//region Load model
	const {
		      scene,
		      nodes,
		      materials,
	      } = useGLTF(modelUrl)
	//endregion

	//region Handlers
	useEffect(
		() => {
			// Set node cast shadow
			Object.values(nodes)
			      .forEach(node => {
				      node.castShadow = true
			      })
		},
		[nodes],
	)

	useEffect(
		() => {
			// Set material envMapIntensity : intensity of the environment light on the materials
			Object.values(materials)
			      .forEach(mat => {
				      mat.envMapIntensity = envMapIntensity
			      })
		},
		[envMapIntensity, materials],
	)

	return (
		<Suspense fallback={null}>
			<group
				ref={ref}
				{...props}
				dispose={null}
			>
				<primitive
					object={scene}
				/>
			</group>
		</Suspense>
	)
})

Model.displayName = 'ApartmentModel'

export default Model
