import {Box, shaderMaterial}    from '@react-three/drei'
import {useSnapshot}            from 'valtio'
import {debugState}             from '../../../state/debug'
import {extend, useThree}       from '@react-three/fiber'
import {useMemo, useRef}        from 'react'
import {apartmentsState}        from '../../../state/apartments/apartments'
import {selectedProgramState}   from '../../../state/selectedProgram'
import {selectedApartmentState} from '../../../state/apartments/selectedApartment'
import {Color}                  from 'three'

const Apartments = () => {
	// Global states
	const selectedProgram         = useSnapshot(selectedProgramState)
	const apartmentsStateSnapshot = useSnapshot(apartmentsState)

	const apartmentsList = useMemo(() => {
		const allApartments = selectedProgram.programData.current?.attributes.apartments ?? []
		return allApartments.map((apartment) => {
			let shouldSelect = true

			// Filter on selected stage
			if (apartmentsStateSnapshot.filters.selectedStage !== null) {
				shouldSelect = shouldSelect && apartment.stage === apartmentsStateSnapshot.filters.selectedStage
			}

			// Filter on selected number of rooms
			if (apartmentsStateSnapshot.filters.selectedNumberOfRooms) {
				shouldSelect = shouldSelect && apartment.rooms === apartmentsStateSnapshot.filters.selectedNumberOfRooms
			}

			apartment.enabled = shouldSelect

			return apartment
		})
	}, [selectedProgram.programData, apartmentsStateSnapshot.filters])

	return (
		<group name={'apartments'}>
			{apartmentsList.map(apartmentData => (
				<ApartmentWireframe
					key={apartmentData.id}
					apartmentData={apartmentData}
				/>
			))}
		</group>
	)
}

const ApartmentWireframe = ({apartmentData}) => {
	const outlineMaterial = useRef()

	// Three JS stuff
	const {scene} = useThree()

	// Global states
	const apartments = useSnapshot(apartmentsState)

	// Hovered
	const hovered = useMemo(() => apartments.hoveredApartment === apartmentData.id, [apartments.hoveredApartment, apartmentData.id])

	return (
		<>
			<Box
				args={[8, 2, 7]}
				position={[apartmentData.position.x, apartmentData.position.y, apartmentData.position.z]}
				rotation={[apartmentData.rotation.x, apartmentData.rotation.y, apartmentData.rotation.z]}
				name={`apartment_overlay_${apartmentData.id}`}
				data_apartment_id={apartmentData.id}
				data_apartment={apartmentData}
				data_apartment_enabled={apartmentData.enabled}

				//region Handlers
				// On click
				onClick={e => {
					e.stopPropagation()
					if (apartmentData.enabled) {
						selectedApartmentState.apartmentData.current = apartmentData
					}
				}}

				//region On mouse hover
				// On pointer enter
				onPointerOver={e => {
					e.stopPropagation()
					// Find first enabled
					const intersection               = e.intersections.find(intersection => intersection.object.data_apartment_enabled)
					apartmentsState.hoveredApartment = intersection?.object.data_apartment_id ?? null
				}}

				// On pointer leave
				onPointerOut={e => {
					e.stopPropagation()
					// Find first enabled
					const intersection               = e.intersections.find(intersection => intersection.object.data_apartment_enabled)
					apartmentsState.hoveredApartment = intersection?.object.data_apartment_id ?? null
				}}
				//endregion

				//region Debug
				onPointerDown={e => {
					e.stopPropagation()
					// If middle button clicked
					// TODO: Remove on production
					if (e.button === 1) {
						const targetObject = scene.getObjectByName(`apartment_overlay_${e.intersections[0]?.object.data_apartment_id}`)
						console.log(targetObject.position)
					}
				}}

				// On context menu click
				onContextMenu={e => {
					e.stopPropagation()
					debugState.transformControls.rightClick = `apartment_overlay_${apartmentData.id}`
				}}
				//endregion
				//endregion
			>
				<outlineMaterial
					ref={outlineMaterial}
					key={OutlineMaterial.key}
					transparent={true}

					// Update uniforms
					uniforms-uColor-value={apartmentData.enabled
					                       ? new Color('#267C55')
					                       : new Color('#c0aa45')}
					uniforms-uOpacity-value={hovered
					                         ? .9
					                         : .5}
				/>
			</Box>
		</>
	)
}

export default Apartments


// Custom shader material for the outlines
const OutlineMaterial = shaderMaterial({
	                                       uColor:   {value: new Color('#267C55')},
	                                       uOpacity: {value: .5},
                                       }, `varying vec2 vUv;
varying vec3 vPosition;
void main() {
    vUv = uv;
    vPosition = position;
    gl_Position = projectionMatrix * modelViewMatrix * vec4(position, 1.0);
}
  `, `uniform vec3 uColor;
  uniform float uOpacity;
varying vec3 vPosition;
varying vec2 vUv;
void main() {
    float alpha;
    float borderWidth = 0.02;

    // Check if the vertex is near the border
    if (vUv.x > 1. - borderWidth || vUv.x < 0. + borderWidth || vUv.y > 1. - borderWidth || vUv.y < 0. + borderWidth){
        // The vertex is near the border, do something
        alpha = 1.0;
    } else {
        // The vertex is not near the border, do something else
        alpha = uOpacity;
    }

    gl_FragColor = vec4(uColor, alpha);
}
  `)

extend({OutlineMaterial})
