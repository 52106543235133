//region Imports
import {useEffect, useMemo, useRef} from 'react'
import * as THREE                   from 'three'
import gsap                         from 'gsap/dist/gsap'
import Model                        from './Model'
import {debugState}                 from '../../../state/debug'
import {useSnapshot}                from 'valtio'
import {selectedApartmentState}     from '../../../state/apartments/selectedApartment'
import {apartmentsState}            from '../../../state/apartments/apartments'
import ApartmentFloor               from './ApartmentFloor'
import {useControls}                from 'leva'
import Compass                      from './Compass'
import {SpotLight, useHelper}       from '@react-three/drei'
import {RectAreaLightHelper}        from 'three/addons/helpers/RectAreaLightHelper'
import {SpotLightHelper}            from 'three'
//endregion

const Apartment = ({
	                   room,
	                   envMapIntensity,
	                   roomLightDistanceScale,
	                   apartmentsHovered,
	                   actions,
	                   ...props
                   }) => {

	const selectedApartment = useSnapshot(selectedApartmentState)
	const apartments        = useSnapshot(apartmentsState)

	//region References
	// Room light ref
	const roomLightRef = useRef()

	// Apartment model ref
	const apartRef = useRef()
	//endregion

	// Memo
	const isCurrentApartment = useMemo(() => {
		return selectedApartment?.id === selectedApartment.apartmentData.current.id
	}, [selectedApartment.apartmentData.current])

	const {
		      roomEnabled,
		      ...roomLight
	      } = useControls('Room light', {
		roomEnabled: true,
		color:       '#ffe3d1',
		intensity:   5,
		distance:    5,
		decay:       2,
		penumbra:    0,
		position:    [0, 2, 0],
	})

	//region Handlers
	//region Room changed
	useEffect(() => {
		if (room) {
			const timeline = gsap.timeline({defaults: {duration: 1}})
			timeline.to(apartRef.current.position, {
				x: room.position[0],
				y: room.position[1],
				z: room.position[2],
			})
		}
	}, [room])
	//endregion

	//region Wireframe
	const wireframeRef = useRef()

	// Colors
	const disabledColor = new THREE.Color('#333333')
	const defaultColor  = new THREE.Color('#cccccc')
	const hoverColor    = new THREE.Color('#FF0A36')

	const hovered  = useMemo(() => {
		// return apartmentsHovered[apartmentsHovered.length - 1] === apartmentData.id ?? false
		return false
	}, [apartmentsHovered])
	const disabled = useMemo(() => {
		let disabled = false
		if (apartments.filters.selectedNumberOfRooms) {
			disabled = disabled || apartments.filters.selectedNumberOfRooms !== selectedApartment.apartmentData.current?.attributes?.rooms
		}
		if (apartments.filters.selectedStage) {
			disabled = disabled || apartments.filters.selectedStage !== selectedApartment.apartmentData.current?.attributes?.stage
		}

		return disabled
	}, [apartments.filters, selectedApartment.apartmentData.current])

	//region Handle apartment hovered
	useEffect(() => {
		let opacity
		if (isCurrentApartment) {
			opacity = 0
		} else if (disabled) {
			opacity = .8
		} else if (hovered) {
			opacity = .2
		} else {
			opacity = .8
		}

		gsap.to(wireframeRef.current?.material, {
			opacity: opacity,
		})

		gsap.to(wireframeRef.current?.material.color, {
			r: disabled
			   ? disabledColor.r
			   : hovered
			     ? hoverColor.r
			     : defaultColor.r,
			g: disabled
			   ? disabledColor.g
			   : hovered
			     ? hoverColor.g
			     : defaultColor.g,
			b: disabled
			   ? disabledColor.b
			   : hovered
			     ? hoverColor.b
			     : defaultColor.b,
		})
	}, [apartmentsHovered, isCurrentApartment, disabled])
	//endregion

	//region Create wireframe box
	useEffect(() => {
		const apartmentBox = new THREE.Box3().setFromObject(apartRef.current)

		// Center
		const center = new THREE.Vector3()
		apartmentBox.getCenter(center)
		wireframeRef.current?.position.copy(center)

		// Size
		const size = new THREE.Vector3()
		apartmentBox.getSize(size)
		            .addScalar(.01)
		wireframeRef.current?.scale.copy(size)
	}, [])
	//endregion
	//endregion

	// Selected apartment changed
	useEffect(() => {
		selectedApartmentState.apartmentGroupName = `apartment_${selectedApartment.apartmentData.current.id}`
	}, [selectedApartment.apartmentGroupName])
	//endregion

	//region Lights
	const directionalLightRef   = useRef()
	// useHelper(directionalLightRef, SpotLightHelper, 1, 'red')
	const directionalLightProps = useControls('Room directional light', {
		intensity: .2,
		color:     '#fff2e4',
		position:  [5, 15, -10],
		distance:  40,
		decay:     1,
		angle:     2.75,
	})
	//endregion

	return (
		<group
			name={`apartment_${selectedApartment.apartmentData.current.id}`}
			{...props}

			onContextMenu={e => {
				e.stopPropagation()
				debugState.transformControls.rightClick = `apartment_${selectedApartment.apartmentData.current.id}`
			}}
		>
			{/* Light */}
			{roomEnabled && <pointLight
				ref={roomLightRef}
				{...roomLight}
			/>}
			<spotLight
				ref={directionalLightRef}
				{...directionalLightProps}
				target={apartRef.current}

				castShadow
			/>

			{/*Model*/}
			<Model
				modelUrl={selectedApartment.apartmentData.current.apartment.data.attributes.glb_file}
				ref={apartRef}
				hovered={hovered}
			/>

			<group
				name={'apartment_information'}
				position-y={-0.1}
			>
				<Compass
					position={[-4.5, 0, -4.5]}
				/>

				{/*Floor*/}
				{selectedApartment.showFloor && <ApartmentFloor/>}
			</group>
		</group>
	)
}
export default Apartment
