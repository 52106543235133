import {forwardRef, Suspense, useRef} from 'react'
import Program                        from '../Program/Program'
import Apartment                      from '../Apartment/Apartment'
import {useSnapshot}                  from 'valtio'
import {selectedApartmentState}       from '../../../state/apartments/selectedApartment'
import {selectedProgramState}         from '../../../state/selectedProgram'
import Lights                         from './Lights'
import {Html, useProgress}            from '@react-three/drei'
import BigCity                        from './BigCity'


const WorldContent = forwardRef(({
	                                 cameraZoom,
	                                 ...props
                                 }, ref) => {

	const selectedApartment = useSnapshot(selectedApartmentState)
	const selectedProgram   = useSnapshot(selectedProgramState)

	const selectedProgramGroupRef   = useRef()
	const selectedApartmentGroupRef = useRef()

	const programRef = useRef()

	return (
		<group
			name={'World content'}
			ref={ref}
		>
			<Lights/>

			<group name={'models'}>
				{/*Program*/}
				<group ref={selectedProgramGroupRef}>{selectedProgram.programData.current && selectedProgram.shown && <Suspense fallback={<Loader/>}>
					<Program
						ref={programRef}
						cameraZoom={cameraZoom}
					/>
					{/*<City/>*/}

					<BigCity/>
				</Suspense>}
				</group>

				{/*Selected apartment*/}
				<group
					name={selectedApartment.apartmentData.current?.apartment.data.attributes.name}
					key={selectedApartment.apartmentData.current?.id}
					ref={selectedApartmentGroupRef}
				>
					{selectedApartment.apartmentData.current?.apartment.data.attributes.glb_file && <Suspense fallback={null}>
						<Apartment
							position={[selectedApartment.apartmentData.current?.position.x, selectedApartment.apartmentData.current?.position.y, selectedApartment.apartmentData.current?.position.z]}
							rotation={[selectedApartment.apartmentData.current?.rotation.x, selectedApartment.apartmentData.current?.rotation.y, selectedApartment.apartmentData.current?.rotation.z]}
						/>
					</Suspense>}
				</group>
			</group>
		</group>
	)
})

function Loader() {
	const {
		      progress,
	      } = useProgress()
	return <Html center><span style={{whiteSpace: 'nowrap'}}>Chargement... {progress.toFixed(2)}%</span></Html>
}

WorldContent.displayName = 'World content'

export default WorldContent
