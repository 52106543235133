import CameraMovementsOverlay    from './CameraMovementsOverlay'
import {overlayState}            from '../../../state/overlay'
import {useSnapshot}             from 'valtio'
import FilterApartmentsOverlay   from './FilterApartmentsOverlay'
import {selectedApartmentState}  from '../../../state/apartments/selectedApartment'
import SelectRoomOverlay         from './SelectRoomOverlay'
import ContactOverlay            from './ContactOverlay'
import FilterNeighborhoodOverlay from './FilterNeighborhoodOverlay'

const BottomRightOverlays = () => {

	const overlayStateSnapshot           = useSnapshot(overlayState)
	const selectedApartmentStateSnapshot = useSnapshot(selectedApartmentState)
	return (
		<>
			{overlayStateSnapshot.currentTab === 'program' && <CameraMovementsOverlay/>}

			{overlayStateSnapshot.currentTab === 'apartments' && <>
				{selectedApartmentStateSnapshot.apartmentData.current === null
				 ? <FilterApartmentsOverlay/>
				 : <SelectRoomOverlay/>}
			</>}

			{(
				 overlayStateSnapshot.currentTab === 'program' || overlayStateSnapshot.currentTab === 'program-information'
			 ) && <ContactOverlay/>}
			
			{overlayStateSnapshot.currentTab === 'neighborhood' && <FilterNeighborhoodOverlay/>}
		</>
	)
}

export default BottomRightOverlays
